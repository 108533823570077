define("discourse/plugins/discourse-display-email/discourse/templates/connectors/user-location-and-website/add-email", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.email}}
    <div class="fa-envelope display-email">
      {{this.model.email}}
    </div>
  {{/if}}
  */
  {
    "id": "d5pYAF3M",
    "block": "[[[41,[30,0,[\"model\",\"email\"]],[[[1,\"  \"],[10,0],[14,0,\"fa-envelope display-email\"],[12],[1,\"\\n    \"],[1,[30,0,[\"model\",\"email\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-display-email/discourse/templates/connectors/user-location-and-website/add-email.hbs",
    "isStrictMode": false
  });
});