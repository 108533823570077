define("discourse/plugins/discourse-display-email/discourse/templates/connectors/user-card-post-names/user-post-names", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.user.email}}
    {{this.user.email}}
  {{/if}}
  */
  {
    "id": "DwwNrnZO",
    "block": "[[[41,[30,0,[\"user\",\"email\"]],[[[1,\"  \"],[1,[30,0,[\"user\",\"email\"]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-display-email/discourse/templates/connectors/user-card-post-names/user-post-names.hbs",
    "isStrictMode": false
  });
});